@charset "utf-8";
@import './common.scss';
/*!
 * @名称：base.css
 * @功能：1、重设浏览器默认样式
 *       2、设置通用原子类
 */
/* 防止用户自定义背景颜色对网页的影响，添加让用户可以自定义字体 */
html {
  background: $bg_cm;
  color: black;
  font-size: 10px;
}
/* 去除浏览器默认滚动条 */
// body::-webkit-srollbar {
//     display: none;
// }

/* 内外边距通常让各个浏览器样式的表现位置不同 */
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td,
hr,
button,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  margin: 0;
  padding: 0;
}

/* 要注意表单元素并不继承父级 font 的问题 */
body,
button,
input,
select,
textarea {
  font: 14px \5b8b\4f53, arial, sans-serif;
}

input,
select,
textarea {
  font-size: 100%;
}

/* 去掉 table cell 的边距并让其边重合 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* ie bug：th 不继承 text-align */
th {
  text-align: inherit;
}

/* 去除默认边框 */
fieldset,
img {
  border: none;
}

/* ie6 7 8(q) bug 显示为行内表现 */
iframe {
  display: block;
}

/* 去掉 firefox 下此元素的边框 */
abbr,
acronym {
  border: none;
  font-variant: normal;
}

/* 一致的 del 样式 */
del {
  text-decoration: line-through;
}

address,
caption,
cite,
code,
dfn,
em,
th,
var {
  font-style: normal;
  font-weight: 500;
}

/* 去掉列表前的标识，li 会继承 */
ol,
ul {
  list-style: none;
}

/* 对齐是排版最重要的因素，别让什么都居中 */
caption,
th {
  text-align: left;
}

/* 来自yahoo，让标题都自定义，适应多个系统应用 */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: 500;
}

h1 {
  font-size: 2.6rem;
}

h2 {
  font-size: 2.4rem;
}

h3 {
  font-size: 2rem;
}

q:before,
q:after {
  content: '';
}

/* 统一上标和下标 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

// /* 让链接在 hover 状态下显示下划线 */
// a:hover {
//     text-decoration: underline;
// }

/* 默认不显示下划线，保持页面简洁 */
ins,
a {
  text-decoration: none;
}

/* 去除 ie6 & ie7 焦点点状线 */
a:focus,
*:focus {
  outline: none;
}

/* 清除浮动 */
.clearfix:before,
.clearfix:after {
  content: '';
  display: block;
}

.clearfix:after {
  clear: both;
  overflow: hidden;
}

.clearfix {
  zoom: 1;
  /* for ie6 & ie7 */
}

.clear {
  clear: both;
  display: block;
  font-size: 0;
  height: 0;
  line-height: 0;
  overflow: hidden;
}

/* 设置显示和隐藏，通常用来与 js 配合 */
.hide {
  display: none;
}

.block {
  display: block;
}

/* 设置浮动，减少浮动带来的 bug */
.fl,
.fr {
  display: inline;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.mr15 {
  margin-right: 15px;
}

// 覆盖antd默认样式
.ant-table-tbody > tr > td {
  padding: 10px 18px !important;
  color: #5a5e66 !important;
}
.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  width: 0px !important;
}
.ant-table-tbody > tr > td:not(:last-child) {
  border-right: 0px !important;
}
.ant-table-thead > tr > th:not(:last-child) {
  border-right: 0px !important;
}
